import { useContext } from 'react';
import Link from 'next/link';

import { FooterMenuBox } from './style';
import { MenuContext } from '../Layout';
import { IMenu } from '../common';

export const FooterMenu = () => {
  const navMenus = useContext(MenuContext);
  const menu = navMenus as IMenu[];
  return (
    <FooterMenuBox>
      { menu && menu !== undefined && menu!== null && menu.length > 0 && 
        menu.map(({ slug, title, id }: IMenu) => {
          if (slug!== '/home') {
            return (
              <Link key={`footer_${id}`} href={slug ?? ''}>
                {title}
              </Link>
            );
          }
        })
      }
    </FooterMenuBox>
  );
};
